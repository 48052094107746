import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'app/services/local-storage.service';
import { RestApiService } from 'app/services/rest-api.service';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  constructor(private translate: TranslateService, private rest: RestApiService, private http: HttpClient, private route: ActivatedRoute, private router: Router, private localStorage: LocalStorageService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      let lang = params.get('lang');
      if (lang != undefined) {
        lang = lang.toUpperCase();
        if (lang === 'DE' || lang === 'FR') {
          this.translate.setDefaultLang(lang);
          this.localStorage.setLanguage(lang);
        }
      }
      let entrepriseId: string = params.get('entrepriseId');
      this.getIpAddress().subscribe(response => {
        this.rest.counterAddWithEmail(response['ip'], entrepriseId).subscribe(
          (response: number) => {
            if (entrepriseId != null) {
              //this.router.navigate(['']);
              window.location.href = 'https://www.arista-dc.ch';
            }

          });
      });
    });
  }

  getIpAddress(): Observable<Object> {
    return this.http.get('https://api.ipify.org/?format=json');
  }

}
