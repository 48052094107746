import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ImagesComponent } from 'app/modal/more-infos-products/images/images.component';
import { PlansComponent } from 'app/modal/more-infos-products/plans/plans.component';
import { VideosComponent } from 'app/modal/more-infos-products/videos/videos.component';
import { Vues360Component } from 'app/modal/more-infos-products/vues360/vues360.component';
import { OrderModalComponent } from 'app/modal/order-modal/order-modal.component';
import { OrderFormModalComponent } from 'app/modal/order-form-modal/order-form-modal.component';
import { DomainService } from 'app/services/domain.service';

@Component({
  selector: 'app-product-order',
  templateUrl: './product-order.component.html',
  styleUrls: ['./product-order.component.css']
})
export class ProductOrderComponent implements OnInit {

  domain: string;

  constructor(private modalService: NgbModal, private domainService: DomainService) { }

  ngOnInit(): void {
    this.domain = this.domainService.getDomain();
  }

  moreInfos(product: string) {
    if (product === 'images') {
      this.modalService.open(ImagesComponent, { size: 'xl', centered: true });
    } else if (product === 'plans') {
      this.modalService.open(PlansComponent, { size: 'xl', centered: true });
    } else if (product === 'view360') {
      this.modalService.open(Vues360Component, { size: 'xl', centered: true });
    } else if (product === 'videos') {
      this.modalService.open(VideosComponent, { size: 'xl', centered: true });
    }
  }

}
