<div class=" card card-raised page-carousel">
    <ngb-carousel>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img src="assets/img/projets/1.jpg" alt="Random first slide">
            </div>
            <div class="carousel-caption">
                <h3 [translate]="'carousel.image1.title'"></h3>
                <p [translate]="'carousel.image1.description'"></p>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img src="assets/img/projets/2.jpg" alt="Random second slide">
            </div>
            <div class="carousel-caption">
                <h3 [translate]="'carousel.image2.title'"></h3>
                <p [translate]="'carousel.image2.description'"></p>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img src="assets/img/projets/3.jpg" alt="Random third slide">
            </div>
            <div class="carousel-caption">
                <h3 [translate]="'carousel.image3.title'"></h3>
                <p [translate]="'carousel.image3.description'"></p>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <img src="assets/img/projets/4.jpg" alt="Random third slide">
            </div>
            <div class="carousel-caption">
                <h3 [translate]="'carousel.image4.title'"></h3>
                <p [translate]="'carousel.image4.description'"></p>
            </div>
        </ng-template>
    </ngb-carousel>
</div>