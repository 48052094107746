<div class="contactus-1 section-image" style="background-image: url('assets/img/sections/soroush-karimi.jpg')" id="contact">
    <div class="container">
        <div class="row">
            <div class="col-md-10 ml-auto mr-auto">
                <div class="card card-contact no-transition">
                    <h3 class="card-title text-center" [translate]="'contact.contact'"></h3>
                    <div class="row">
                        <div class="col-md-12 mr-auto">
                            <form id="contact-form" method="post" role="form" [formGroup]="form" (ngSubmit)="onSubmit()"
                                  wtx-context="F261600C-FD7B-4265-B20D-41B14646B925">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="alert alert-success col-md-12 mr-auto success" role="alert" *ngIf="success" [translate]="'message.sended.contact'">
                                            
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group label-floating">
                                                <label class="control-label" [translate]="'contact.firstname'"></label>
                                                <input class="form-control"
                                                       placeholder="{{ 'placeholder.firstname.contact' | translate }}"
                                                       formControlName="firstName"
                                                       type="text"
                                                       wtx-context="FDF8AB04-4D6C-4C76-85AA-DDAB8669AADF">
                                                <div *ngIf="isSubmitted&&f.firstName.errors" class="text-danger">
                                                    <p *ngIf="f.firstName.errors.required" [translate]="'contact.placeholder.firstname'"></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group label-floating">
                                                <label class="control-label" [translate]="'contact.lastname'"></label>
                                                <input class="form-control"
                                                       placeholder="{{ 'placeholder.lastname.contact' | translate }}"
                                                       formControlName="lastName"
                                                       type="text"
                                                       wtx-context="4D23EF74-2D28-4515-86DA-4F815BBD1A69">
                                                <div *ngIf="isSubmitted&&f.lastName.errors" class="text-danger">
                                                    <p *ngIf="f.lastName.errors.required"></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group label-floating">
                                        <label class="control-label" for="email" [translate]="'contact.email'"></label>
                                        <input class="form-control"
                                               id="email"
                                               formControlName="email"
                                               required="required"
                                               placeholder="{{ 'placeholder.email.contact' | translate }}"
                                               type="email"
                                               wtx-context="9CB5F509-D4AC-491C-840A-A1F10394306E">
                                        <div *ngIf="isSubmitted&&f.email.errors" class="text-danger">
                                            <p *ngIf="f.email.errors.required" [translate]="'contact.placeholder.email.required'"></p>
                                            <p *ngIf="f.email.errors.pattern" [translate]="'contact.placeholder.email.pattern'"></p>
                                        </div>

                                    </div>
                                    <div class="form-group label-floating">
                                        <label class="control-label" [translate]="'contact.message'"></label>
                                        <textarea class="form-control"
                                                  id="message"
                                                  formControlName="message"
                                                  placeholder="{{ 'placeholder.message.contact' | translate }}"
                                                  rows="6"></textarea>
                                        <div *ngIf="isSubmitted&&f.message.errors" class="text-danger">
                                            <p *ngIf="f.message.errors.required" [translate]="'contact.placeholder.message'"></p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <button class="btn btn-primary pull-right"
                                                    type="submit" [translate]="'contact.send'">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
