import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './index/index.component';
import { SuccessPayementComponent } from './success-payement/success-payement.component';
import { CounterComponent } from './counter/counter.component';

const routes: Routes =[
    { path: 'index',                component: IndexComponent },
    { path: 'index/:entrepriseId',  component: IndexComponent },
    { path: 'index/:lang/:entrepriseId',  component: IndexComponent },
    { path: 'successPayement',      component: SuccessPayementComponent },
    { path: 'counter',              component: CounterComponent },
    { path: '', redirectTo: 'index', pathMatch: 'full' }
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes,{
          useHash: true
        })
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
