import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RestApiService } from 'app/services/rest-api.service';
import { Counter } from 'app/Types/Counter';
import { CounterOfDay } from 'app/Types/CounterOfDay';

@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css']
})
export class CounterComponent implements OnInit {
  dates:CounterOfDay[] = [];

  constructor(private rest: RestApiService, private http: HttpClient) { }

  ngOnInit(): void {


    for (let index = 0; index < 30; index++) {
      let date:Date = new Date();
      date.setDate(date.getDate() - index);
      this.rest.getCountAndListEmailsFromDate(date).subscribe(
        (response: CounterOfDay) => {
          let dateCounter:CounterOfDay = {
            date: date,
            counter: response.counter,
            entreprisesId: response.entreprisesId,
          }
          this.dates.push(dateCounter);
          this.dates = this.dates.sort(function(a,b){
            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
          });
        });
    }

  }
}
