import { Injectable } from '@angular/core';
import { StripeService } from 'ngx-stripe';
import { RestApiService } from './rest-api.service';
import {Observable} from 'rxjs';
type sessionId = {
  sessionId: string;
}
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private rest: RestApiService, private stripeService: StripeService) { }
 
  pay(formJson: string, amount: string) {
    this.rest.payment(formJson, amount)
      .subscribe((resp: sessionId) => {
        let sessionId: string =  resp.sessionId;
        this.stripeService.redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as argument here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: sessionId
        }).subscribe(resp => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
      });
  }
    createPayement(productId:number,nbOfImages:number): Observable<Object> {
        return  this.rest.createPayment(productId, nbOfImages);
    }
    addDescription(paymentId:number,description:string): Observable<Object> {
        return  this.rest.addDescriptionPayment(paymentId, description);
    }
}
