<div class="modal-header">
    <div class="row">
        <div class="col-md-11">
            <p class="modal-title" [translate]="'modal.videos.title'"></p>
        </div>
        <div class="col-md-1">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-md-7 col-sm-6">
                <div class="ml-auto mr-auto" id="carousel">
                    <ngb-carousel>
                        <ng-template ngbSlide>
                            <div class="picsum-img-wrapper">
                                <vm-player noControls>
                                    <vm-video cross-origin="true" poster="">
                                        <source data-src="assets/video/video2.mp4" type="video/mp4" />
                                    </vm-video>
                                    <vm-default-ui>
                                        <vm-controls>
                                        </vm-controls>
                                    </vm-default-ui>
                                </vm-player>
                            </div>
                        </ng-template>
                    </ngb-carousel>
                </div>
            </div>
            <div class="col-md-5 col-sm-6">
                <p [translate]="'modal.videos.description'"></p>
                <div class="row">
                    <div class="col-md-10">
                        <h3><span [translate]="'modal.videos.toAsk'"></span></h3>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-12"><button (click)="order()" [disabled]="shouldButonDisabled"
                            class="btn btn-danger btn-block btn-round"><span [translate]="'modal.videos.order'"></span>
                            &nbsp;<i class="fa fa-chevron-right"></i></button></div>
                </div>
            </div>
        </div>
    </div>
</div>