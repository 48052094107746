import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { FileUploader } from 'ng2-file-upload';
import {PaymentService} from 'app/services/payment.service';
import {OrderModalComponent} from '../order-modal/order-modal.component';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';

const UPLOAD_URL = environment.apiUrl +'/api/payment/uploadAttachment';
@Component({
  selector: 'app-order-modal',
  templateUrl: './order-attachement-modal.component.html',
  styleUrls: ['./order-attachement-modal.component.css']
})
export class OrderAttachementModalComponent implements OnInit {
  @Input()
  firstItemPrice: number;
  @Input()
  nextItemsPrice: number;
  @Input()
  totalPrice: number;
  @Input()
  nbOfImages: number;
  @Input()
  productId: string;
  @Input()
  payment;
  uploader:FileUploader;
  @Input()
  description:string;
  response:string;
  constructor(private payementService: PaymentService, private modalService: NgbModal, public activeModal: NgbActiveModal) {
    this.response = '';

  }
  ngOnInit(): void {
    this.uploader = new FileUploader({
      url: UPLOAD_URL,
      autoUpload: true,
      additionalParameter:{payment:this.payment}
    });
    this.uploader.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
    };
    this.uploader.response.subscribe( res => this.response = res );
  }

  continue(description){
    this.payementService.addDescription(this.payment,description.value).subscribe();
    const modalRef = this.modalService.open(OrderModalComponent, {size: 'm', centered: true});
    modalRef.componentInstance.paymentId = this.payment;
    modalRef.componentInstance.firstItemPrice = this.firstItemPrice;
    modalRef.componentInstance.nextItemsPrice = this.nextItemsPrice;
    modalRef.componentInstance.totalPrice = this.totalPrice;
    modalRef.componentInstance.nbOfImages = this.nbOfImages;
    modalRef.componentInstance.productId = this.productId;
  }
}
