<nav class="navbar navbar-expand-lg fixed-top nav-down navbar-transparent" color-on-scroll="500">
    <div class="container">
        <a href="#" class="navbar-brand d-none d-lg-block">
            <img src="assets/img/logoClair.png" height="90" alt="logo">
        </a>
        <div class="navbar-translate d-none d-lg-block">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation"
                aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-bar bar1"></span>
                <span class="navbar-toggler-bar bar2"></span>
                <span class="navbar-toggler-bar bar3"></span>
            </button>
        </div>
        <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a href="#features" class="nav-link" [translate]="'product.nav'"></a>
                </li>
                <li class="nav-item">
                    <a href="#timeline" class="nav-link" [translate]="'process.nav'"></a>
                </li>
                <li class="nav-item">
                    <a href="#gallery" class="nav-link" [translate]="'gallery.nav'"></a>
                </li>
                <li class="nav-item">
                    <a href="#contact" class="nav-link" [translate]="'contact.nav'"></a>
                </li>
                <li class="nav-item">
                    <div ngbDropdown class="dropdown">
                        <div class="nav-link dropdown-toggle" ngbDropdownToggle>
                            {{lang}}
                        </div>
                        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-warning">
                            <a class="dropdown-item" (click)="changeLanguage('fr')">FR</a>
                            <a class="dropdown-item" (click)="changeLanguage('de')">DE</a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
