import { Inject, Injectable } from '@angular/core';
import { WINDOW } from './WINDOW_PROVIDERS';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(@Inject(WINDOW) private window: Window) { }

  getDomain(): string{
    let hostname =  this.window.location.hostname;
    return hostname.substring(hostname.lastIndexOf('.') + 1);
  }

}
