<div class="pricing-3 section-image" style="background-image: url('assets/img/sections/daniel-olahs.jpg')">
    <div class="container">
        <div class="row">
            <div class="col-md-6 ml-auto mr-auto text-center">
                <h2 class="title" [translate]="'productOrder.title'"></h2>
                <h5 class="description" [translate]="'productOrder.description'"></h5>
            </div>
        </div>
        <div class="space-top"></div>
        <div class="row">
            <div class="col-md-3 ml-auto mr-auto">
                <div class="card card-pricing">
                    <div class="card-body">
                        <h6 class="card-category" [translate]="'productOrder.product1.category'"></h6>
                        <div class="card-icon"><i class="nc-icon nc-album-2"></i></div>
                        <h3 class="card-title"><span [translate]="'productOrder.product1.title'"></span><span>{{domain == 'ch' ? 'CHF' : '€'}}</span></h3>
                        <p class="card-description" [translate]="'productOrder.product1.description'"></p>
                        <div class="card-footer"><a class="btn btn-info btn-round card-link" href="javascript:void(0)"
                                (click)="moreInfos('images')" [translate]="'productOrder.order'"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ml-auto mr-auto">
                <div class="card card-pricing">
                    <div class="card-body">
                        <h6 class="card-category" [translate]="'productOrder.product2.category'"></h6>
                        <div class="card-icon"><i class="nc-icon nc-album-2"></i></div>
                        <h3 class="card-title"><span [translate]="'productOrder.product2.title'"></span><span>{{domain == 'ch' ? 'CHF' : '€'}}</span></h3>
                        <p class="card-description" [translate]="'productOrder.product2.description'"></p>
                        <div class="card-footer"><a class="btn btn-info btn-round card-link" href="javascript:void(0)"
                                (click)="moreInfos('view360')" [translate]="'productOrder.order'"></a>
                        </div> 
                    </div>
                </div>
            </div>
            <div class="col-md-3 ml-auto mr-auto">
                <div class="card card-pricing">
                    <div class="card-body">
                        <h6 class="card-category" [translate]="'productOrder.product3.category'"></h6>
                        <div class="card-icon"><i class="nc-icon nc-map-big"></i></div>
                        <h3 class="card-title"><span [translate]="'productOrder.product3.title'"></span><span>{{domain == 'ch' ? 'CHF' : '€'}}</span></h3>
                        <p class="card-description" [translate]="'productOrder.product3.description'"></p>
                        <div class="card-footer"><a class="btn btn-info btn-round card-link" href="javascript:void(0)"
                                (click)="moreInfos('plans')" [translate]="'productOrder.order'"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 ml-auto mr-auto">
                <div class="card card-pricing">
                    <div class="card-body">
                        <h6 class="card-category" [translate]="'productOrder.product4.category'"></h6>
                        <div class="card-icon"><i class="nc-icon nc-map-big"></i></div>
                        <h3 class="card-title" [translate]="'productOrder.product4.title'"></h3>
                        <p class="card-description" [translate]="'productOrder.product4.description'"></p>
                        <div class="card-footer"><a class="btn btn-info btn-round card-link" href="javascript:void(0)"
                                (click)="moreInfos('videos')" [translate]="'productOrder.order'"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>