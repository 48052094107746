import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationLoader implements TranslateLoader {
  apiURL = environment.apiUrl;
  
  constructor(private http: HttpClient) { } 

  getTranslation(lang: string): Observable<any> {
    return this.getTranslationsByLanguage(lang);
  }

  getTranslationsByLanguage(language_code: string): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.apiURL + '/api/translation/all', language_code, { headers: headers });
  }

}