import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderModalComponent } from 'app/modal/order-modal/order-modal.component';
import { ProductService } from 'app/services/product.service';
import { Product } from 'app/Types/Product';
import {OrderAttachementModalComponent} from '../../order-attachement-modal/order-attachement-modal.component';
import {PaymentService} from '../../../services/payment.service';
import { DomainService } from 'app/services/domain.service';

@Component({
  selector: 'app-videos360',
  templateUrl: './vues360.component.html',
  styleUrls: ['./vues360.component.css']
})
export class Vues360Component implements OnInit {
  firstItemPrice: number;
  nextItemsPrice: number;
  totalPrice: number;
  productId: number;
  error: boolean = true;
  shouldButonDisabled: boolean = false;
  domain:string;
  @Input() formDataDetails = {
    viewSize: '',
    nbOfImages: 1
  }
  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private paymentService: PaymentService, private productService: ProductService, private domainService: DomainService) { }

  ngOnInit(): void {
    this.determinePrice();
    this.resetForms();
    this.domain = this.domainService.getDomain();
  }
  onChangeViewSize() {
    this.determinePrice();
  }

  resetForms(){
    this.formDataDetails.viewSize = '';
    this.firstItemPrice = 0;
    this.nextItemsPrice = 0;
    this.totalPrice = 0;
  }


  getPrice(productiId: number) {
    this.productService.getProductById(productiId).subscribe((resp: Product) => {
      if (resp != null) {
        this.firstItemPrice = Number(resp.firstItemPrice);
        this.nextItemsPrice = Number(resp.nextItemsPrice);
        this.totalPrice = this.firstItemPrice + ((this.formDataDetails.nbOfImages - 1) * this.nextItemsPrice);
        this.productId = productiId;
      } else {
        this.firstItemPrice = 0;
        this.nextItemsPrice = 0;
        this.totalPrice = 0;
      }
      this.error = false;
    }, ()=>{
      this.error = true;
    });
  }

  determinePrice() {
    this.getPrice(7);
  }

  order() {
    this.determinePrice();
    if (this.shouldButonDisabled) {
      return;
    }
    this.paymentService.createPayement(this.productId,this.formDataDetails.nbOfImages).subscribe((resp) => {
      const modalRef = this.modalService.open(OrderAttachementModalComponent,{size: 'xl'});
      modalRef.componentInstance.payment = resp;
      modalRef.componentInstance.firstItemPrice = this.firstItemPrice;
      modalRef.componentInstance.nextImagesPrice = this.nextItemsPrice;
      modalRef.componentInstance.totalPrice = this.totalPrice;
      modalRef.componentInstance.nbOfImages = this.formDataDetails.nbOfImages;
      modalRef.componentInstance.productId = this.productId;
    });


  }

  shouldDisableSubmitButton(): void {
    if (this.error) {
      this.shouldButonDisabled = true;
      return;
    }
    if (this.formDataDetails.viewSize) {
      this.shouldButonDisabled = false;
      return;
    }
    this.shouldButonDisabled = true;
  }
}
