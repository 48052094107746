import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderModalComponent } from 'app/modal/order-modal/order-modal.component';
import { ProductService } from 'app/services/product.service';
import { Product } from 'app/Types/Product';
import {OrderAttachementModalComponent} from '../../order-attachement-modal/order-attachement-modal.component';
import {PaymentService} from '../../../services/payment.service';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  firstItemPrice: number;
  nextItemsPrice: number;
  totalPrice: number;
  productId: number;
  error: boolean = true;
  shouldButonDisabled: boolean = false;
  @Input() formDataDetails = {
    videoType: '',
    interiorDuration: '',
    exteriorDuration: '',
    nbOfImages: 1
  }
  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private paymentService: PaymentService, private productService: ProductService) { }

  ngOnInit(): void {
    this.resetForms();
  }

  resetForms() {
    this.formDataDetails.exteriorDuration = '';
    this.formDataDetails.interiorDuration = '';
    this.formDataDetails.nbOfImages = 1;
    this.firstItemPrice = 0;
    this.nextItemsPrice = 0;
    this.totalPrice = 0;
  }

  onChangeVideoType() {
    if (this.formDataDetails.videoType === '1') {
      this.resetForms();
    } else if (this.formDataDetails.videoType === '2') {
      this.resetForms();
    }
  }

  order() {
    this.determinePrice();
    if (this.shouldButonDisabled) {
      return;
    }
    this.paymentService.createPayement(19,this.formDataDetails.nbOfImages).subscribe((resp) => {
      const modalRef = this.modalService.open(OrderAttachementModalComponent,{size: 'xl'});
      modalRef.componentInstance.payment = resp;
      modalRef.componentInstance.firstItemPrice = this.firstItemPrice;
      modalRef.componentInstance.nextImagesPrice = this.nextItemsPrice;
      modalRef.componentInstance.totalPrice = this.totalPrice;
      modalRef.componentInstance.nbOfImages = this.formDataDetails.nbOfImages;
      modalRef.componentInstance.productId = this.productId;
    });


  }

  getPrice(productiId: number) {
    this.productService.getProductById(productiId).subscribe((resp: Product) => {
      if (resp != null) {
        this.firstItemPrice = Number(resp.firstItemPrice);
        this.nextItemsPrice = Number(resp.nextItemsPrice);
        this.totalPrice = this.firstItemPrice + ((this.formDataDetails.nbOfImages - 1) * this.nextItemsPrice);
        this.productId = productiId;
      } else {
        this.firstItemPrice = 0;
        this.nextItemsPrice = 0;
        this.totalPrice = 0;
      }
      this.error = false;
    }, () => {
      this.error = true;
    });
  }

  determinePrice() {
    if (this.formDataDetails.interiorDuration == '1') {
      this.getPrice(19);
    } else if (this.formDataDetails.interiorDuration == '2') {
      this.getPrice(20);
    } else if (this.formDataDetails.interiorDuration == '3') {
      this.getPrice(21);
    } else if (this.formDataDetails.interiorDuration == '4') {
      this.getPrice(22);
    } else if (this.formDataDetails.exteriorDuration == '1') {
      this.getPrice(23);
    } else if (this.formDataDetails.exteriorDuration == '2') {
      this.getPrice(24);
    } else if (this.formDataDetails.exteriorDuration == '3') {
      this.getPrice(25);
    } else if (this.formDataDetails.exteriorDuration == '4') {
      this.getPrice(26);
    } else {
      this.firstItemPrice = 0;
      this.nextItemsPrice = 0;
      this.totalPrice = 0;
    }
  }

  shouldDisableSubmitButton(): void {
    if (this.error) {
      this.shouldButonDisabled = true;
      return;
    }
    if (this.formDataDetails.interiorDuration || this.formDataDetails.exteriorDuration) {
      this.shouldButonDisabled = false;
      return;
    }
    this.shouldButonDisabled = true;
  }
}
