<div class="section section-feature cd-section" id="features">

    <div class="features-3">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="container">
                            <h2 class="title" [translate]="'productDescription.title'"></h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="info info-horizontal">
                                <div class="icon icon-success"><i class="nc-icon nc-layout-11"></i></div>
                                <div class="description"> 
                                    <h4 class="info-title" [translate]="'productDescription.product1.title'"></h4>
                                    <p [translate]="'productDescription.product1.description'"></p><a class="btn btn-link"
                                        (click)="moreInfos('image')" [translate]="'productDescription.moreInfosLink'"></a>
                                </div>
                            </div>
                            <div class="info info-horizontal">
                                <div class="icon icon-warning"><i class="nc-icon nc-palette"></i></div>
                                <div class="description">
                                    <h4 class="info-title" [translate]="'productDescription.product2.title'"></h4>
                                    <p [translate]="'productDescription.product2.description'"></p><a class="btn btn-link" href="javascript:void(0)"  (click)="moreInfos('plans')" [translate]="'productDescription.moreInfosLink'"></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="info info-horizontal">
                                <div class="icon icon-danger"><i class="nc-icon nc-touch-id"></i></div>
                                <div class="description">
                                    <h4 class="info-title" [translate]="'productDescription.product3.title'"></h4>
                                    <p [translate]="'productDescription.product3.description'"></p><a class="btn btn-link" href="javascript:void(0)"  (click)="moreInfos('video360')" [translate]="'productDescription.moreInfosLink'"></a>
                                </div>
                            </div>
                            <div class="info info-horizontal">
                                <div class="icon icon-primary"><i class="nc-icon nc-delivery-fast"></i></div>
                                <div class="description">
                                    <h4 class="info-title" [translate]="'productDescription.product4.title'"></h4>
                                    <p [translate]="'productDescription.product4.description'"></p><a class="btn btn-link" href="javascript:void(0)"  (click)="moreInfos('video')" [translate]="'productDescription.moreInfosLink'"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<!--                 <div class="col-md-5 ml-auto">
                    <div class="iphone-container"><img src="assets/img/sections/iphone.png"></div>
                </div> -->
            </div>
        </div>
    </div>

</div>