<!-- <div *ngIf="galleryImages1 && galleryImages"> -->
<div class="row">
  <div class="col-md-12 text-center font-weight-bold display-3" [translate]="'realisation.gallery'">

  </div>
</div>
<div class="row">
  <div class="col-md-12 text-center font-weight-bold h2" [translate]="'images.gallery'">
  </div>
</div>
<ngx-gallery [options]="galleryOptions" [images]="galleryImages" id="gallery"></ngx-gallery>

<div class="row">
  <div class="col-md-12 text-center font-weight-bold h2" [translate]="'video.gallery'">
  </div>
</div>

<iframe src="https://player.vimeo.com/video/726630200?h=0792a9979d" class=" offset-3 col-md-6 video" height="564" frameborder="0"
  allow="autoplay; fullscreen" allowfullscreen></iframe>

<iframe src="https://player.vimeo.com/video/656528071?h=de8ff8ec30" class="col-md-6 video" height="564" frameborder="0"
  allow="autoplay; fullscreen" allowfullscreen></iframe>

<iframe src="https://player.vimeo.com/video/654958424?h=bdcc0dd8e2" class="col-md-6 video" height="564" frameborder="0"
  allow="autoplay; fullscreen" allowfullscreen></iframe>
<div class="row">
  <div class="col-md-12 text-center font-weight-bold h2" [translate]="'plan3d.gallery'">
  </div>
</div>
<div class="section">
  <div class="container">
    <div class="article">
      <div class="row">
        <div class="col-md-4 ml-auto mr-auto">
          <div class="card card-blog card-plain text-center">
            <div class="card-image"><img class="img img-raised" src="./assets/img/plan3d/plan1.jpg"></div>
          </div>
        </div>

        <div class="col-md-4 ml-auto mr-auto">
          <div class="card card-blog card-plain text-center">
            <div class="card-image"><img class="img img-raised" src="./assets/img/plan3d/plan2.jpg"></div>
          </div>
        </div>
        <div class="col-md-4 ml-auto mr-auto">
          <div class="card card-blog card-plain text-center">
            <div class="card-image"><img class="img img-raised" src="./assets/img/plan3d/plan3.jpg"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 text-center font-weight-bold h2" [translate]="'view360.gallery'">
  </div>
</div>
<div class="row justify-content-lg-center">
  <iframe class="col-xs-10 col-sm-10 col-md-10" height="500"
    src="https://data.sentiovr.com/spaces/33459/space_1637841283/vtour/tour.html" allowfullscreen="true"
    mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
</div>

<!--   <div class="row">
    <div class="col-md-8">
      <ngx-gallery [options]="galleryOptions1" [images]="galleryImages1" id="gallery1"></ngx-gallery>
    </div>
  </div> -->


<!-- </div> -->