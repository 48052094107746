<div class="modal-header">
  <div class="row">
      <div class="col-md-11">
          <p class="modal-title">Vos coordonnées</p>
      </div>
      <div class="col-md-1">
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
          </button>
      </div> 
  </div>
</div>
<div class="container contact-form">
<!--   <div class="contact-image">
    <img src="./assets/img/projets/logo.png" alt="rocket_contact" />
  </div> -->
  <form (ngSubmit)="onSubmit()" [formGroup]="form">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <input type="text" name="txtName" class="form-control" placeholder="Nom *" formControlName="lastName"/>
        </div>

      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-group">
            <input type="text" name="txtFirstName" class="form-control" placeholder="Prénom *" formControlName="firstName" />
            
          </div>

        </div>
      </div>
    </div>
    <div *ngIf="isSubmitted&&f.lastName.errors" class="text-danger">
      <p *ngIf="f.lastName.errors.required">Veuillez entrer votre prénom</p>
    </div>
    <div *ngIf="isSubmitted&&f.firstName.errors" class="text-danger">
      <p *ngIf="f.firstName.errors.required">Veuillez entrer votre nom</p>
    </div>
    <div class="row">
      <div class="col-md-6">

        <div class="form-group">
          <input type="text" name="txtPhone" class="form-control" placeholder="Téléphone *" formControlName="phone" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <div class="form-group">
            <input type="text" name="txtEmail" class="form-control" placeholder="Email *" formControlName="email" />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isSubmitted&&f.phone.errors" class="text-danger">
      <p *ngIf="f.phone.errors.required">Veuillez entrer votre téléphone</p>
    </div>
    <div *ngIf="isSubmitted&&f.email.errors" class="text-danger">
      <p *ngIf="f.email.errors.required">Veuillez entrer votre email</p>
      <p *ngIf="f.email.errors.pattern">Veuillez entre un email valable</p>
    </div>
    
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <input type="text" name="txtAdress" class="form-control" placeholder="Adresse *" formControlName="address" />
        </div>
      </div>
    </div>
    <div *ngIf="isSubmitted&&f.phone.errors" class="text-danger">
      <p *ngIf="f.phone.errors.required">Veuillez entrer votre adresse</p>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <input type="text" name="txtZip" class="form-control" placeholder="CP *" formControlName="cp" />
        </div>
      </div>
      <div class="col-md-8">
        <div class="form-group">
          <input type="text" name="txtCity" class="form-control" placeholder="Localité *" formControlName="city" />
        </div>
      </div>
    </div>
    <div *ngIf="isSubmitted&&f.cp.errors" class="text-danger">
      <p *ngIf="f.cp.errors.required">Veuillez entrer votre code postal</p>
    </div>
    <div *ngIf="isSubmitted&&f.city.errors" class="text-danger">
      <p *ngIf="f.city.errors.required">Veuillez entrer votre localité</p>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <input type="submit" name="btnSubmit" class="form-control btnContact" value="Envoyer la demande d'offre" />
        </div>
      </div>
    </div>
  </form>
</div>
