<div class="modal-content">
    <div class="modal-header">
        <h5 class="modal-title">Confirmation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Nous vous remercions pour votre demande d'offre, nous allons prendre contact avec vous dans les meilleurs
            délais.</p>
    </div>
</div>