<div class="modal-header">
    <div class="row">
        <div class="col-md-11">
            <p class="modal-title" [translate]="'modal.image.title'"></p>
        </div>
        <div class="col-md-1">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-sm-6">
                    <div class="ml-auto mr-auto" id="carousel">
                        <ngb-carousel>
                            <ng-template ngbSlide>
                                <div class="picsum-img-wrapper">
                                    <img src="assets/img/projets/1.jpg">
                                </div>
                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>
                <div class="col-md-5 col-sm-6">
                    <p [translate]="'modal.image.description'"></p>
                    <hr>
                    <div class="row">
                        <div class="col-md-10">
                            <div class="form-group">
                                <label for="imageType" [translate]="'modal.image.select.type'"></label>
                                <select class="form-control" id="imageType" (change)="onChangeImageType()"
                                    [(ngModel)]="formDataDetails.imageType">
                                    <option value="1" [translate]="'modal.image.select.type.1'"></option>
                                    <option value="2" [translate]="'modal.image.select.type.2'"></option>
                                </select>
                            </div>
                            <div class="form-group" *ngIf="formDataDetails.imageType == '1'">
                                <label for="interiorImageObjectType"
                                    [translate]="'modal.image.select.interiorImageObjectType'"></label>
                                <select (change)="determinePrice()" class="form-control" id="interiorImageObjectType"
                                    [(ngModel)]="formDataDetails.interiorImageObjectType">
                                    <option value="1" [translate]="'modal.image.select.interiorImageObjectType.1'">
                                    </option>
                                    <option value="2" [translate]="'modal.image.select.interiorImageObjectType.2'">
                                    </option>
                                    <option value="3" [translate]="'modal.image.select.interiorImageObjectType.3'">
                                    </option>
                                </select>
                            </div>
                            <div class="form-group" *ngIf="formDataDetails.imageType == '2'">
                                <label for="exteriorImageObjectType"
                                    [translate]="'modal.image.select.exteriorImageObjectType'"></label>
                                <select (change)="determinePrice()" class="form-control" id="exteriorImageObjectType"
                                    [(ngModel)]="formDataDetails.exteriorImageObjectType">
                                    <option value="1" [translate]="'modal.image.select.exteriorImageObjectType.1'">
                                    </option>
                                    <option value="2" [translate]="'modal.image.select.exteriorImageObjectType.2'">
                                    </option>
                                    <option value="3" [translate]="'modal.image.select.exteriorImageObjectType.3'">
                                    </option>
                                </select>
                            </div>
                            <div class="form-group"
                                *ngIf="formDataDetails.imageType == '2' && formDataDetails.exteriorImageObjectType == '1'">
                                <label for="housingObjectType"
                                    [translate]="'modal.image.select.housingObjectType'"></label>
                                <select (change)="determinePrice()" class="form-control" id="housingObjectType"
                                    [(ngModel)]="formDataDetails.housingObjectType">
                                    <option value="1" [translate]="'modal.image.select.housingObjectType.1'"></option>
                                    <option value="2" [translate]="'modal.image.select.housingObjectType.2'"></option>
                                    <option value="3" [translate]="'modal.image.select.housingObjectType.3'"></option>
                                </select>
                            </div>
                            <div class="form-group"
                                *ngIf="formDataDetails.imageType == '1' || formDataDetails.imageType == '2'">
                                <label for="nbOfImages" [translate]="'modal.image.quantity'"></label>
                                <input (change)="determinePrice()" (change)="onChangeNbOfImages()"
                                    [(ngModel)]="formDataDetails.nbOfImages" type="number" min="1" class="form-control"
                                    id="nbOfImages" placeholder="1">
                            </div>
                            <div class="form-group" *ngIf="formDataDetails.nbOfImages > 1">
                                <label for="nbOfImagesNight" [translate]="'modal.image.quantityNight'"></label>
                                <input (change)="determinePrice()" (change)="onChangeNbOfImages()"
                                    [(ngModel)]="formDataDetails.nbOfImagesNight" type="number" min="0"
                                    max="{{formDataDetails.nbOfImages - 1}}" class="form-control" id="nbOfImagesNight"
                                    placeholder="0">
                            </div>

                        </div>
                    </div>
                    <hr>
                    <div class="row" *ngIf="totalPrice > 0">
                        <div class="col-md-10">
                            <h5 *ngIf="this.formDataDetails.nbOfImages > 1"><span
                                    [innerHtml]="'modal.image.firstImagePrice' | translate"></span>:
                                <b>{{firstItemPrice}} {{domain == 'ch' ? 'CHF' : '€'}}</b>
                            </h5>
                            <h5 *ngIf="this.formDataDetails.nbOfImages > 1"><span
                                    [translate]="'modal.image.nextImagesPrice'"></span>: <b>{{nextItemsPrice}} {{domain == 'ch' ? 'CHF' : '€'}}</b>
                            </h5>
                            <h5 *ngIf="this.formDataDetails.nbOfImagesNight > 0"><span
                                    [translate]="'modal.image.nightImagesPrice'"></span>: <b>{{nightItemsPrice}} {{domain == 'ch' ? 'CHF' : '€'}}</b>
                            </h5>
                            <h5><span [translate]="'modal.image.totalPrice'"></span>: <b>{{totalPrice}} {{domain == 'ch' ? 'CHF' : '€'}}</b></h5>
                        </div>
                    </div>
                    <hr class="row" *ngIf="totalPrice > 0">
                    <div class="row">
                        <div class="col-md-12"><button (click)="order()"
                                [disabled]="shouldButonDisabled" class="btn btn-danger btn-block btn-round"><span
                                    [translate]="'modal.image.order'"></span> &nbsp;<i
                                    class="fa fa-chevron-right"></i></button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>