import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestApiService } from './rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private rest: RestApiService) { }

  getProductById(id: number): Observable<Object> {
    return this.rest.getProductById(id);
  }

}
