import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from './services/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    constructor(public translate: TranslateService, private localStorage: LocalStorageService) {

    }

    ngOnInit() {
        this.translate.addLangs(['fr', 'de', 'en']);
        if(this.localStorage.getLanguage() != undefined && this.localStorage.getLanguage() != ''){
            this.translate.setDefaultLang(this.localStorage.getLanguage());
        }else{
            this.translate.setDefaultLang('FR');
            this.localStorage.setLanguage('FR');
        }
    }

}
