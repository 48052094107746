import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {formatDate} from '@angular/common';
import { ProductService } from 'app/services/product.service';
import { RestApiService } from 'app/services/rest-api.service';
import { InfoPayment } from 'app/Types/InfoPayment';
import { Product } from 'app/Types/Product';
import { DomainService } from 'app/services/domain.service';

@Component({
  selector: 'app-success-payement',
  templateUrl: './success-payement.component.html',
  styleUrls: ['./success-payement.component.css']
})
export class SuccessPayementComponent implements OnInit {

  infos: InfoPayment;
  date: string;
  product: Product
domain:string;
  constructor(private route: ActivatedRoute, private rest:RestApiService, private productService: ProductService, private domainService: DomainService) { }

  ngOnInit(): void {
    this.domain = this.domainService.getDomain();
    this.date = formatDate(new Date(), 'dd.MM.yyyy', 'en');
    this.route.queryParams
      .subscribe(params => {
        this.rest.confirmation(params.paymentId)
          .subscribe((respInfoPayment: InfoPayment)  => {
            this.infos = respInfoPayment;
            this.productService.getProductById(this.infos.productId).subscribe((respProduct: Product) => {
              this.product = respProduct;
            });
          });
      }
      );
    
  }


}
