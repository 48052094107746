<div class="modal-header">
    <div class="row">
        <div class="col-md-11">
            <p class="modal-title">Description du projet</p>
        </div>
        <div class="col-md-1">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div> 
    </div>
</div>
<div class="container">
    <div class="col-12">
        <div>
            <h3>
                Veuillez décrire votre projet
            </h3>
        </div>
        <div>
            <textarea [name]="description" rows="7" class="descpription" #description>

            </textarea>
        </div>
    </div>
    <div class="col-12">
        <h3>Ajouter des documents</h3><br/>
    </div>
        <div class="row col-12">
            <input type="file" ng2FileSelect [uploader]="uploader" multiple/>
        </div>
        <div class="col-md-12" style="margin-bottom: 40px">
            <table class="table">
                <thead>
                <tr>
                    <th>Nom du fichier</th>
                    <th>Taille</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of uploader.queue">
                    <td>
                        <strong>{{ item?.file?.name }}</strong>
                    </td>
                    <td *ngIf="uploader.options.isHTML5" nowrap>
                        {{ item?.file?.size / 1024 / 1024 | number:'.2' }}MB
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    <div>
        <button type="button" class="btn btn-danger float-right mb-4" (click)="continue(description)">Valider</button>
    </div>

</div>
