import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagesComponent } from 'app/modal/more-infos-products/images/images.component';
import { PlansComponent } from 'app/modal/more-infos-products/plans/plans.component';
import { VideosComponent } from 'app/modal/more-infos-products/videos/videos.component';
import { Vues360Component } from 'app/modal/more-infos-products/vues360/vues360.component';

@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.css']
})
export class ProductDescriptionComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  moreInfos(product: string) {
    if (product === 'image') {
      this.modalService.open(ImagesComponent, { size: 'xl', centered: true });
    } else if (product === 'plans') {
      this.modalService.open(PlansComponent, { size: 'xl', centered: true });
    } else if (product === 'video360') {
      this.modalService.open(Vues360Component, { size: 'xl', centered: true });
    } else if (product === 'video') {
      this.modalService.open(VideosComponent, { size: 'xl', centered: true });
    }
  }

  close(){
    this.modalService.dismissAll;
  }

}
