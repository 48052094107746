import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from 'app/services/payment.service';
import { QuoteRequesService } from 'app/services/quote-request.service';
import { SuccessQuoteRequestComponent } from '../success-quote-request/success-quote-request.component';

@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.component.html',
  styleUrls: ['./order-modal.component.css']
})
export class OrderModalComponent implements OnInit {
  @Input()
  firstItemPrice: number;
  @Input()
  nextImagesPrice: number;
  @Input()
  totalPrice: number;
  @Input()
  nbOfImages: number;
  @Input()
  productId: string;
  @Input()
  paymentId: number;

  payButton: boolean = true;

  form: FormGroup;
  constructor(private stripeService: StripeService, private http: HttpClient, private modalService: NgbModal, private quoteRequestService: QuoteRequesService, public activeModal: NgbActiveModal) {

    this.form = new FormGroup({
      email: new FormControl(null, [
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ]),
      firstName: new FormControl('', [
        Validators.required
      ]),
      lastName: new FormControl('', [
        Validators.required
      ]),
      phone: new FormControl('', [
        Validators.required
      ]),
      address: new FormControl('', [
        Validators.required
      ]),
      cp: new FormControl('', [
        Validators.required
      ]),
      city: new FormControl('', [
        Validators.required
      ])

    })
  }

  ngOnInit(): void {

  }

  isSubmitted = false;

  onSubmit() {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    this.modalService.dismissAll();
    const formObj = this.form.getRawValue();
    formObj.paymentId = this.paymentId;
    formObj.productId = this.productId;
    formObj.quantity = this.nbOfImages;
    const serializedForm = JSON.stringify(formObj);
    
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    this.quoteRequestService.order(serializedForm, this.totalPrice.toString());
  }

  get f() {
    return this.form.controls;
  }


}
