import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomainService } from 'app/services/domain.service';
import { PaymentService } from 'app/services/payment.service';
import { ProductService } from 'app/services/product.service';
import { Product } from 'app/Types/Product';
import { OrderAttachementModalComponent } from '../../order-attachement-modal/order-attachement-modal.component';
@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.css']
})
export class ImagesComponent implements OnInit {
  firstItemPrice: number;
  nextItemsPrice: number;
  nightItemsPrice: number; totalPrice: number;
  productId: number;
  error: boolean = true;
  shouldButonDisabled: boolean = true;
  domain: string;
  @Input() formDataDetails = {
    imageType: '',
    interiorImageObjectType: '',
    exteriorImageObjectType: '',
    nbOfImages: 1,
    housingObjectType: '',
    nbOfImagesNight: 0
  }

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private paymentService: PaymentService, private productService: ProductService, private domainService: DomainService) {

  }

  ngOnInit(): void {
    this.firstItemPrice = 0;
    this.nextItemsPrice = 0;
    this.totalPrice = 0;
    this.domain = this.domainService.getDomain();
  }

  onChangeNbOfImages() {
    if (this.formDataDetails.nbOfImages < 1) {
      this.formDataDetails.nbOfImages = 1;
    }
  }

  onChangeImageType() {

    if (this.formDataDetails.imageType === '1') {
      this.resetForms();
    } else if (this.formDataDetails.imageType === '2') {
      this.resetForms();
    }
  }

  resetForms() {
    this.formDataDetails.housingObjectType = '';
    this.formDataDetails.interiorImageObjectType = '';
    this.formDataDetails.exteriorImageObjectType = '';
    this.formDataDetails.nbOfImages = 1;
    this.firstItemPrice = 0;
    this.nextItemsPrice = 0;
    this.totalPrice = 0;
    this.shouldDisableSubmitButton();
  }

  addFile() {

  }

  order() {
    this.determinePrice();
    if (this.shouldButonDisabled) {
      return;
    }
    this.paymentService.createPayement(this.productId, this.formDataDetails.nbOfImages).subscribe((resp) => {
      const modalRef = this.modalService.open(OrderAttachementModalComponent, { size: 'xl' });
      modalRef.componentInstance.payment = resp;
      modalRef.componentInstance.firstItemPrice = this.firstItemPrice;
      modalRef.componentInstance.nextImagesPrice = this.nextItemsPrice;
      modalRef.componentInstance.totalPrice = this.totalPrice;
      modalRef.componentInstance.nbOfImages = this.formDataDetails.nbOfImages;
      modalRef.componentInstance.productId = this.productId;
    });


  }

  getPrice(productiId: number) {
    this.productService.getProductById(productiId).subscribe((resp: Product) => {
      if (resp != null) {
        this.firstItemPrice = Number(resp.firstItemPrice);
        this.nextItemsPrice = Number(resp.nextItemsPrice); this.nightItemsPrice = Number(resp.nightItemsPrice);
        this.totalPrice = this.firstItemPrice + ((this.formDataDetails.nbOfImages - 1) * this.nextItemsPrice) + (this.formDataDetails.nbOfImagesNight * this.nightItemsPrice);
        this.productId = productiId;
      } else {
        this.firstItemPrice = 0;
        this.nextItemsPrice = 0;
        this.totalPrice = 0;
      }
      this.error = false;
      this.shouldDisableSubmitButton();
    }, (error) => {
      console.log("product error :" + error.toString());
      this.error = true;
      this.shouldDisableSubmitButton();
    });
  }

  determinePrice() {
    if (this.formDataDetails.nbOfImages < 2) {
      this.formDataDetails.nbOfImagesNight = 0;
    }
    if (this.formDataDetails.imageType == '1' && this.formDataDetails.interiorImageObjectType == '1') {
      this.getPrice(1);
    } else if (this.formDataDetails.imageType == '1' && this.formDataDetails.interiorImageObjectType == '2') {
      this.getPrice(2);
    } else if (this.formDataDetails.imageType == '1' && this.formDataDetails.interiorImageObjectType == '3') {
      this.getPrice(3);
    } else if (this.formDataDetails.imageType == '2' && this.formDataDetails.exteriorImageObjectType == '1' && this.formDataDetails.housingObjectType == '1') {
      this.getPrice(27);
    } else if (this.formDataDetails.imageType == '2' && this.formDataDetails.exteriorImageObjectType == '1' && this.formDataDetails.housingObjectType == '2') {
      this.getPrice(28);
    } else if (this.formDataDetails.imageType == '2' && this.formDataDetails.exteriorImageObjectType == '1' && this.formDataDetails.housingObjectType == '3') {
      this.getPrice(29);
    } else if (this.formDataDetails.imageType == '2' && this.formDataDetails.exteriorImageObjectType == '2') {
      this.getPrice(4);
    } else if (this.formDataDetails.imageType == '2' && this.formDataDetails.exteriorImageObjectType == '3') {
      this.getPrice(5);
    } else {
      this.firstItemPrice = 0;
      this.nextItemsPrice = 0;
      this.nightItemsPrice = 0;
      this.totalPrice = 0;
      this.shouldDisableSubmitButton();
    }
  }

  shouldDisableSubmitButton(): void {
     if (this.error) {
      this.shouldButonDisabled = true;
      return;
    }
    if (this.formDataDetails.imageType == '1' && this.formDataDetails.interiorImageObjectType == '1') {
      this.shouldButonDisabled = false;
      return;
    } else if (this.formDataDetails.imageType == '1' && this.formDataDetails.interiorImageObjectType == '2') {
      this.shouldButonDisabled = false;
      return;
    } else if (this.formDataDetails.imageType == '1' && this.formDataDetails.interiorImageObjectType == '3') {
      this.shouldButonDisabled = false;
      return;
    } else if (this.formDataDetails.imageType == '2' && this.formDataDetails.exteriorImageObjectType == '1' && this.formDataDetails.housingObjectType == '1') {
      this.shouldButonDisabled = false;
      return;
    } else if (this.formDataDetails.imageType == '2' && this.formDataDetails.exteriorImageObjectType == '1' && this.formDataDetails.housingObjectType == '2') {
      this.shouldButonDisabled = false;
      return;
    } else if (this.formDataDetails.imageType == '2' && this.formDataDetails.exteriorImageObjectType == '1' && this.formDataDetails.housingObjectType == '3') {
      this.shouldButonDisabled = false;
      return;
    } else if (this.formDataDetails.imageType == '2' && this.formDataDetails.exteriorImageObjectType == '2') {
      this.shouldButonDisabled = false;
      return;
    } else if (this.formDataDetails.imageType == '2' && this.formDataDetails.exteriorImageObjectType == '3') {
      this.shouldButonDisabled = false;
      return;
    }
    this.shouldButonDisabled = true;
  }

}

