import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RestApiService } from 'app/services/rest-api.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-contactus',
    templateUrl: './contactus.component.html',
    styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
    form: FormGroup;
    success: boolean = false;

    isSubmitted = false;

    constructor(private rest: RestApiService, private router: Router) {
        this.form = new FormGroup({
            email: new FormControl(null, [
                Validators.required,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
            ]),
            firstName: new FormControl('', [
                Validators.required
            ]),
            lastName: new FormControl('', [
                Validators.required
            ]),
            message: new FormControl('', [
                Validators.required
            ])
        })
    }


    ngOnInit() {
    }

    onSubmit() {
        this.isSubmitted = true;
        if (this.form.invalid) {
            return;
        }
        const formObj = this.form.getRawValue();
        const serializedForm = JSON.stringify(formObj);
        this.rest.contactUs(serializedForm).subscribe(
            (response: string) => {
                this.form.reset();
                Object.keys(this.form.controls).forEach(key => {
                    this.form.get(key).setErrors(null) ;
                  });
                  this.success = true;
                  setTimeout(() => {
                    this.success = false;
                  },6000);
            },
            (error) => console.log(error)
        )
    }
    get f() {
        return this.form.controls;
    }
}
