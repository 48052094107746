<div class="modal-header">
    <div class="row">
        <div class="col-md-11">
            <p class="modal-title" [translate]="'modal.vue360.title'"></p>
        </div>
        <div class="col-md-1">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="section">
    <div class="container">
        <div class="row">
            <div class="col-md-7 col-sm-6">
                <div class="ml-auto mr-auto" id="carousel">
                    <ngb-carousel>
                        <ng-template ngbSlide>
                            <div class="picsum-img-wrapper">
                                <img src="assets/img/projets/2.jpg">
                            </div>
                        </ng-template>
                    </ngb-carousel>
                </div>
            </div>
            <div class="col-md-5 col-sm-6">
                <p [translate]="'modal.vue360.description'"></p>
                <hr>
                <div class="row">
                    <div class="col-md-10">
                        <h3><span [translate]="'modal.vue360.totalPrice'"></span>: <b>{{totalPrice}} {{domain == 'ch' ? 'CHF' : '€'}}</b></h3> 
                        <h6><span [translate]="'modal.vue360.containImage'"></span></h6>  
                    </div>
                </div>
                <hr *ngIf="totalPrice > 0">
                <div class="row">
                    <div class="col-md-12"><button (click)="order()" [disabled]="shouldButonDisabled" class="btn btn-danger btn-block btn-round"><span [translate]="'modal.vue360.order'"></span> &nbsp;<i class="fa fa-chevron-right"></i></button></div>
                </div>
            </div>
        </div>
    </div>
</div>
