import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';

import { IndexComponent } from './index/index.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ContactusComponent } from './sections/contactus/contactus.component';
import { OrderFormModalComponent } from './modal/order-form-modal/order-form-modal.component';
import { FooterComponent } from './sections/footer/footer.component';
import { CarouselComponent } from './sections/carousel/carousel.component';
import { ProductDescriptionComponent } from './sections/product-description/product-description.component';
import { TimelineComponent } from './sections/timeline/timeline.component';
import { ProductOrderComponent } from './sections/product-order/product-order.component';
import { AristaGalleryComponent } from './sections/arista-gallery/arista-gallery.component';
import { OrderModalComponent } from './modal/order-modal/order-modal.component';
import { NgxStripeModule } from 'ngx-stripe';
import { ImagesComponent } from './modal/more-infos-products/images/images.component';
import { Vues360Component } from './modal/more-infos-products/vues360/vues360.component';
import { PlansComponent } from './modal/more-infos-products/plans/plans.component';
import { VideosComponent } from './modal/more-infos-products/videos/videos.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { SuccessPayementComponent } from './success-payement/success-payement.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslationLoader } from './services/TranslateLoader';
import { RestApiService } from './services/rest-api.service';
import { OrderAttachementModalComponent } from './modal/order-attachement-modal/order-attachement-modal.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NavComponent } from './nav/nav.component';
import { SuccessQuoteRequestComponent } from './modal/success-quote-request/success-quote-request.component';

import { VimeModule } from '@vime/angular';
import { CounterComponent } from './counter/counter.component';
import { WINDOW_PROVIDERS } from './services/WINDOW_PROVIDERS';



// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslationLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        IndexComponent,
        OrderFormModalComponent,
        ContactusComponent,
        FooterComponent,
        CarouselComponent,
        ProductDescriptionComponent,
        TimelineComponent,
        ProductOrderComponent,
        AristaGalleryComponent,
        OrderModalComponent,
        ImagesComponent,
        Vues360Component,
        PlansComponent,
        VideosComponent,
        SuccessPayementComponent,
        OrderAttachementModalComponent,
        NavComponent,
        SuccessQuoteRequestComponent,
        CounterComponent
    ],
    imports: [
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        RouterModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxStripeModule.forRoot('pk_test_51Jc4dhJdRzVEI4f3lixnKWnk0zHkMnZBCUGSXv5caNIMsN3UttZSDwGB7K45bO3CwY89KVXR0uxR4y5ltNyICpGk00pKKzkrJ8'),
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        NgxGalleryModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
            defaultLanguage: 'fr'
        }),
        FileUploadModule,
        VimeModule
    ],
    providers: [
        WINDOW_PROVIDERS
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
