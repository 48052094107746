<div class="project-4 section section-dark" id="timeline">
    <div class="container">
        <div class="row">
            <div class="col-md-8 ml-auto mr-auto text-center">
                <h2 class="title" [translate]="'timeline.title'"></h2>
                <h5 class="description" [translate]="'timeline.description'"></h5>
            </div>
        </div>
        <div class="space-top"></div>
        <div class="row">
            <div class="col-md-5 ml-auto">
                <div class="card" data-background="image"
                    style="background-image: url('assets/img/projets/7.jpg')">
                    <div class="card-icon"></div>
                </div> 
            </div>
            <div class="col-md-5 mr-auto">
                <div class="card card-plain">
                    <div class="card-body">
                        <h6 class="card-category" [translate]="'timeline.step1.category'"></h6>
                        <h2 class="card-title" [translate]="'timeline.step1.title'"></h2>
                        <p class="card-description" [innerHTML]="'timeline.step1.description' | translate"></p>
                    </div>
                </div>
            </div>
        </div><br>
        <hr><br>
        <div class="row">
            <div class="col-md-5 ml-auto">
                <div class="card card-plain">
                    <div class="card-body">
                        <h6 class="card-category" [translate]="'timeline.step2.category'"></h6>
                        <h2 class="card-title" [translate]="'timeline.step2.title'"></h2>
                        <p class="card-description" [innerHTML]="'timeline.step2.description' | translate"></p>

                    </div>
                </div>
            </div>
            <div class="col-md-5 mr-auto">
                <div class="card" data-background="image"
                    style="background-image: url('assets/img/projets/8.jpg')">
                    <div class="card-icon"></div>
                </div>
            </div>
        </div><br>
        <hr><br>
        <div class="row">
            <div class="col-md-5 ml-auto">
                <div class="card" data-background="image"
                    style="background-image: url('assets/img/projets/9.jpg')">
                    <div class="card-icon"></div>
                </div>
            </div>
            <div class="col-md-5 mr-auto">
                <div class="card card-plain">
                    <div class="card-body">
                        <h6 class="card-category" [translate]="'timeline.step3.category'"></h6>
                        <h2 class="card-title" [translate]="'timeline.step3.title'"></h2>
                        <p class="card-description" [innerHTML]="'timeline.step3.description' | translate"></p>
                    </div>
                </div>
            </div>
        </div><br>
        <hr><br>
        <div class="row">
            <div class="col-md-5 ml-auto">
                <div class="card card-plain">
                    <div class="card-body">
                        <h6 class="card-category" [translate]="'timeline.step4.category'"></h6>
                        <h2 class="card-title" [translate]="'timeline.step4.title'"></h2>
                        <p class="card-description" [innerHTML]="'timeline.step4.description' | translate"></p>

                    </div>
                </div>
            </div>
            <div class="col-md-5 mr-auto">
                <div class="card" data-background="image"
                    style="background-image: url('assets/img/projets/10.jpg')">
                    <div class="card-icon"></div>
                </div>
            </div>
        </div>
    </div>
</div>