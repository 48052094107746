import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getLanguage(): string {
    if (localStorage) {
      return localStorage['language'] || "";
    }
    else {
      return "";
    }
  }

  setLanguage(language: string) {
    if (localStorage) {
      localStorage['language'] = language;
    }
  }

  getUsername(): string {
    if (localStorage) {
      return localStorage['username'] || "";
    }
    else {
      return "";
    }
  }

  setUsername(username: string) {
    if (localStorage) {
      localStorage['username'] = username;
    }
  }
}