import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderModalComponent } from 'app/modal/order-modal/order-modal.component';
import { ProductService } from 'app/services/product.service';
import { Product } from 'app/Types/Product';
import {OrderAttachementModalComponent} from '../../order-attachement-modal/order-attachement-modal.component';
import {PaymentService} from '../../../services/payment.service';
import { DomainService } from 'app/services/domain.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css']
})
export class PlansComponent implements OnInit {
  firstItemPrice: number;
  nextItemsPrice: number;
  totalPrice: number;
  productId: number;
  error: boolean = true;
  shouldButonDisabled: boolean = true;

  domain:string;

  @Input() formDataDetails = {
    planType: '',
    sizeNormal: '',
    sizeInteractive: '',
    nbOfImages: 1
  }

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal, private paymentService: PaymentService, private productService: ProductService, private domainService: DomainService) { }

  ngOnInit(): void {
    this.resetForms();
    console.log("IN");
    this.domain = this.domainService.getDomain();
  }

  resetForms() {
    this.formDataDetails.sizeInteractive = '';
    this.firstItemPrice = 0;
    this.nextItemsPrice = 0;
    this.totalPrice = 0;
  }

  onChangePlanType() {
    if (this.formDataDetails.planType === '1') {
      this.resetForms();
    } else if (this.formDataDetails.planType === '2') {
      this.resetForms();
    }
  }

  order() {
    this.determinePrice();
    if (this.shouldButonDisabled) {
      return;
    }
    this.paymentService.createPayement(this.productId,this.formDataDetails.nbOfImages).subscribe((resp) => {
      const modalRef = this.modalService.open(OrderAttachementModalComponent,{size: 'xl'});
      modalRef.componentInstance.payment = resp;
      modalRef.componentInstance.firstItemPrice = this.firstItemPrice;
      modalRef.componentInstance.nextImagesPrice = this.nextItemsPrice;
      modalRef.componentInstance.totalPrice = this.totalPrice;
      modalRef.componentInstance.nbOfImages = this.formDataDetails.nbOfImages;
      modalRef.componentInstance.productId = this.productId;
    });


  }

  getPrice(productiId: number) {
    this.productService.getProductById(productiId).subscribe((resp: Product) => {
      this.error = false;
      this.shouldDisableSubmitButton();
      if (resp != null) {
        this.firstItemPrice = Number(resp.firstItemPrice);
        this.nextItemsPrice = Number(resp.nextItemsPrice);
        this.totalPrice = this.firstItemPrice;
        this.productId = productiId;
      } else {
        this.firstItemPrice = 0;
        this.nextItemsPrice = 0;
        this.totalPrice = 0;
      }
    }, () => {
      this.error = true;
      this.shouldDisableSubmitButton();
    });
  }

  determinePrice() {
    if (this.formDataDetails.planType == '1') {
      this.getPrice(11);
    } else if (this.formDataDetails.planType == '2') {
      this.getPrice(12);
    } else {
      this.firstItemPrice = 0;
      this.nextItemsPrice = 0;
      this.totalPrice = 0;
    }
  }

  shouldDisableSubmitButton(): void {
    if (this.error) {
      this.shouldButonDisabled = true;
      return;
    }
    if (this.formDataDetails.planType == '1' || this.formDataDetails.planType == '2') {
      this.shouldButonDisabled = false;
      return;
    }
    this.shouldButonDisabled = true;
  }

}
