import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Counter } from 'app/Types/Counter';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  apiURL = environment.apiUrl;

  constructor(private http: HttpClient) { }

  createOrderData(formData): Observable<string> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post(this.apiURL + '/api/data/createOrderData', JSON.stringify(formData), { headers, responseType: 'text' })
      .pipe(
        catchError(this.handleError)
      );
  }

  confirmation(paymentId: string): Observable<Object> {
    const headers = new HttpHeaders({  'Content-Type': 'application/json' });
    return this.http.post(this.apiURL + '/api/payment/confirmation', paymentId, { headers: headers });
  }

  payment(formJson: string, amount: string): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'amount': amount });
    return this.http.post(this.apiURL + '/api/payment/sessionId', formJson, { headers: headers });
  }
  quoteRequest(formJson: string, paymentId: string): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'paymentId': paymentId });
    return this.http.post(this.apiURL + '/api/payment/quoteRequest', formJson, { headers: headers });
  }
  createPayment(productId:number,nbOfImages:number): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.apiURL + '/api/payment/createPayment', {productId:productId,nbOfImages:nbOfImages} , { headers: headers });
  }
  addDescriptionPayment(paymentId:number,description:string): Observable<Object> {
    const options = {
      headers: new HttpHeaders().append('Content-Type', 'application/json'),
      params: new HttpParams().append('paymentId', paymentId).append("description",description)
    };

    return this.http.post(this.apiURL + '/api/payment/addDescription', {paymentId:paymentId,description:description} , options);
  }
  contactUs(serializedForm): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers, responseType: 'text' };
    return this.http.post(this.apiURL + '/api/mail/send_email_contactus', serializedForm, { headers: headers, responseType: 'text' });
  }
  galleryImgUrls(): Observable<Object> {
    return this.http.get(this.apiURL + '/api/img/list');
  }
  planImgUrls(): Observable<Object> {
    return this.http.get(this.apiURL + '/api/img/listPlan');
  }
  sendConfirmation(htmlContent): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers: headers };
    return this.http.post(this.apiURL + '/api/mail/send_email_contactus', htmlContent, options);
  }

  getProductById(id: number): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.apiURL + '/api/product/getProduct', id, { headers: headers });
  }

  getCountFromDate(date: Date): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.apiURL + '/api/counter/getCountFromDate', date, { headers: headers });
  }

  getCountAndListEmailsFromDate(date: Date): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(this.apiURL + '/api/counter/getCountAndListEmailsFromDate', date, { headers: headers });
  }

  counterAdd(ipAddress: string): Observable<Object> {
    return this.counterAddWithEmail(ipAddress, '');
  }
  counterAddWithEmail(ipAddress: string, entrepriseId: string): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let counter: Counter = {
      date: new Date(),
      counter: ipAddress,
      ipAddress: ipAddress,
      entrepriseId: entrepriseId
    }
    return this.http.post(this.apiURL + '/api/counter/add', counter, { headers: headers });
  }

  getTranslationsByLanguage(language_code: string): Observable<Object> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
    return this.http.post(this.apiURL + '/api/translate/translations', language_code, { headers: headers });
  }

  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
