import { Injectable } from '@angular/core';
import { StripeService } from 'ngx-stripe';
import { RestApiService } from './rest-api.service';
import {Observable} from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessQuoteRequestComponent } from 'app/modal/success-quote-request/success-quote-request.component';
type sessionId = {
  sessionId: string;
}
@Injectable({
  providedIn: 'root'
})
export class QuoteRequesService {

  constructor(private rest: RestApiService, private modalService: NgbModal) { }
 
  order(formJson: string, paymentId: string) {
    this.rest.quoteRequest(formJson, paymentId)
      .subscribe((resp: sessionId) => {
       this.modalService.open(SuccessQuoteRequestComponent,{size: 's'});
      });
  }
}
