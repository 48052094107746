<div class="container mt-5 mb-5"  *ngIf="product">
    <div class="row d-flex justify-content-center">
        <div class="col-md-8">
            <div class="card">
                <div class="text-left logo p-2 px-5"> <img src="./assets/img/projets/logo.png" width="100"> </div>
                <div class="invoice p-5">
                    <h5>Votre commande est confirmé!</h5> <span class="font-weight-bold d-block mt-4">Bonjour {{infos.firstName}} {{infos.lastName}},</span> <span>Nous allons prendre contact avec vous rapidement!</span>
                    <div class="payment border-top mt-3 mb-3 border-bottom table-responsive">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="py-2"> <span class="d-block text-muted">Date de commande</span> <span>{{date}}</span> </div>
                                    </td>
                                    <td>
                                        <div class="py-2"> <span class="d-block text-muted">Payement</span> <span><img src="https://img.icons8.com/color/48/000000/mastercard.png" width="20" /></span> </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="product border-bottom table-responsive">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <td width="20%"> <img src="./{{product.imageUrl}}" width="90"> </td>
                                    <td width="60%"> <span class="font-weight-bold">{{product.invoiceFirstLineItemName}}</span>
                                    </td>
                                    <td width="20%">
                                        <div class="text-right"> <span class="font-weight-bold">{{product.firstItemPrice}} {{domain == 'ch' ? 'CHF' : '€'}}</span> </div>
                                    </td>
                                </tr>
                                <tr *ngIf="infos.quantity > 1">
                                    <td width="20%"> <img src="./{{product.imageUrl}}" width="70"> </td>
                                    <td width="60%"> <span class="font-weight-bold">{{product.invoiceNextLineItemsName}}</span>
                                        <div class="product-qty"> <span class="d-block">Quantity:{{infos.quantity-1}}</span></div>
                                    </td>
                                    <td width="20%">
                                        <div class="text-right"> <span class="font-weight-bold">{{product.nextItemsPrice * (infos.quantity -1)}} {{domain == 'ch' ? 'CHF' : '€'}}</span> </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row d-flex justify-content-end">
                        <div class="col-md-5">
                            <table class="table table-borderless">
                                <tbody class="totals">
                                    <tr>
                                        <td>
                                            <div class="text-left"> <span class="text-muted">Sous total</span> </div>
                                        </td>
                                        <td>
                                            <div class="text-right"> <span *ngIf="infos.quantity > 1">{{product.firstItemPrice +  (product.nextItemsPrice * (infos.quantity -1))}} {{domain == 'ch' ? 'CHF' : '€'}}</span> <span *ngIf="infos.quantity == 1">{{product.firstItemPrice }} {{domain == 'ch' ? 'CHF' : '€'}}</span> </div>
                                        </td>
                                    </tr>
                                    <tr  *ngIf="infos.quantity > 1">
                                        <td>
                                            <div class="text-left"> <span class="text-muted">Rabais accordé</span> </div>
                                        </td>
                                        <td>
                                            <div class="text-right">  <span class="text-success">{{(product.firstItemPrice * infos.quantity) - (product.firstItemPrice +  (product.nextItemsPrice * (infos.quantity -1)))}} {{domain == 'ch' ? 'CHF' : '€'}}</span> </div>
                                        </td>
                                    </tr>
                                    <tr class="border-top border-bottom">
                                        <td>
                                            <div class="text-left"> <span class="font-weight-bold">Total</span> </div>
                                        </td>
                                        <td>
                                            <div class="text-right"> <span class="font-weight-bold"> <span *ngIf="infos.quantity > 1">{{product.firstItemPrice +  (product.nextItemsPrice * (infos.quantity -1))}} {{domain == 'ch' ? 'CHF' : '€'}}</span> <span *ngIf="infos.quantity == 1">{{product.firstItemPrice }} {{domain == 'ch' ? 'CHF' : '€'}}</span> </span> </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <p class="font-weight-bold mb-0">Merci de travailler avec nous!</p> <span>Arista Design Concept</span>
                </div>
            </div>
        </div>
    </div>
</div>
