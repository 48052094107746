<div class="page-header">
    <app-nav></app-nav>
    <app-carousel></app-carousel>
</div>

<div class="wrapper">
    <app-arista-gallery></app-arista-gallery>
    <app-product-description></app-product-description>
    <app-timeline></app-timeline>
    <app-product-order></app-product-order>
    <app-contactus></app-contactus>
</div>
