import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxGalleryImageSize, NgxGalleryOptions, NgxGalleryOrder } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { RestApiService } from 'app/services/rest-api.service';

@Component({
  selector: 'app-arista-gallery',
  templateUrl: './arista-gallery.component.html',
  styleUrls: ['./arista-gallery.component.css']
})
export class AristaGalleryComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];


  constructor(private rest: RestApiService, private changeDetector: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.galleryImages = [];
    this.rest.galleryImgUrls().subscribe(
      (response) => {
        console.log(response);
        for (let entry of <Array<string>>response) {
          let path = 'assets/img/gallery/' + entry;
          this.galleryImages.push({
            small: path,
            medium: path,
            big: path
          });
        }

        this.galleryOptions = [
          {
            width: '100px',
            height: '200px',
            thumbnailsColumns: 3,
            thumbnailsRows: Math.ceil(this.galleryImages.length / 3),
            imageAnimation: NgxGalleryAnimation.Slide,
            thumbnailsOrder: NgxGalleryOrder.Page,
            image: false
          },
          {
            breakpoint: 800,
            width: '10%',
            height: '600px',
            imagePercent: 80,
            thumbnailsPercent: 50,
            thumbnailsMargin: 20,
            thumbnailMargin: 20
          },
          {
            breakpoint: 400,
            preview: true
          }
        ];
        this.changeDetector.markForCheck();
      });
  }

}
