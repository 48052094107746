<footer class="footer section-dark">
    <div class="container">
        <div class="row justify-content-lg-center">
            <div class="col-4">
                <span class="copyright" [translate]="'copyright'"></span>
            </div>
        </div>
        <div class="row justify-content-lg-center">
            <div class="col-2">
                <a href="assets/pdf/LegalNotice.pdf" target="_blank">Legal notice</a>
            </div>
            <div class="col-3">
                <a href="assets/pdf/PrivacyPolicy.pdf" target="_blank">Privacy policy</a>
            </div>
            <div class="col-2">
                <a href="assets/pdf/CookiesPolicy.pdf" target="_blank">Cookies policy</a>
            </div>
        </div>
        <div class="row justify-content-lg-center">
            <div class="col-2">
                <a href="assets/pdf/MentionsLegales.pdf" target="_blank">Mentions légales</a>
            </div>
            <div class="col-3">
                <a href="assets/pdf/PolitiqueDeConfidentialité.pdf" target="_blank">Politique de confidentialité</a>
            </div>
            <div class="col-2">
                <a href="assets/pdf/PolitiqueDeCookies.pdf" target="_blank">Politique de cookies</a>
            </div>
        </div>
    </div>
</footer>