import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'app/services/local-storage.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  lang!: string;
  constructor(private translate: TranslateService, private localStorage: LocalStorageService) { }

  ngOnInit(): void {
    this.lang = this.localStorage.getLanguage();
  }

  changeLanguage(lang: string) {
    if (lang) {
      this.lang = lang;
      this.translate.setDefaultLang(lang);
      this.localStorage.setLanguage(lang);
    }
  }

}
