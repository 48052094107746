<div class="modal-header">
    <div class="row">
        <div class="col-md-11">
            <p class="modal-title" [translate]="'modal.plan3d.title'"></p>
        </div>
        <div class="col-md-1">
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="section">
        <div class="container">
            <div class="row">
                <div class="col-md-7 col-sm-6">
                    <div class="ml-auto mr-auto" id="carousel">
                        <ngb-carousel>
                            <ng-template ngbSlide>
                                <div class="picsum-img-wrapper">
                                    <img src="assets/img/projets/3.jpg">
                                </div>

                            </ng-template>
                        </ngb-carousel>
                    </div>
                </div>

                <div class="col-md-5 col-sm-6">
                    <p [translate]="'modal.plan3d.description'"></p>
                    <hr>
                    <div class="row">
                        <div class="col-md-10">
                            <div class="form-group">
                                <label for="planType" [translate]="'modal.plan3d.select.type'"></label>
                                <select class="form-control" id="planType" (change)="determinePrice()"
                                    [(ngModel)]="formDataDetails.planType">
                                    <option value="1" [translate]="'modal.plan3d.select.type.1'"></option>
                                    <option value="2" [translate]="'modal.plan3d.select.type.2'"></option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="row" *ngIf="totalPrice > 0">
                        <div class="col-md-10">
                            <h3><span [translate]="'modal.plan3d.totalPrice'"></span>: <b>{{totalPrice}} {{domain == 'ch' ? 'CHF' : '€'}}</b></h3>
                            <h6><span [innerHtml]="'modal.plan3d.minSize' | translate"></span></h6>
                        </div>
                    </div>
                    <hr *ngIf="totalPrice > 0">
                    <div class="row">
                        <div class="col-md-12"><button (click)="order()" [disabled]="shouldButonDisabled"
                                class="btn btn-danger btn-block btn-round" [translate]="'modal.plan3d.order'"> &nbsp;<i
                                    class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>